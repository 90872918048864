<template>
<div ref="modalFaq" class="phone-modal phone-modal-port-search with-nav" style="bottom: -779px;" :class="{active: isFaqModalOpen}">
	<span class="modal-line"></span>
	<div class="modal-title-bar">
		<div class="icon-wrap">
			<FaqIcon style="width: 27px; height: auto;" class="icon faq-icon" />
			<span>FAQs</span>
		</div><!-- icon wrap -->
		<GreyCloseIcon @click="closeFaqModal" style="width: 31px; height: 31px;" class="icon close" />
	</div><!-- modal title bar -->
	<div class="text-block section-gap">
		<p class="no-space"><strong>{{ title }}</strong></p>
		<p class="no-space">{{ subtitle }}</p>
	</div>
	<div class="section-gap none-bottom card-wrap">
		<Card
			class="card-block-grey card-block-full"
			icon="FaqIcon"
			category="Faqs"
			title="Find more questions like this"
			text="Lorem ipsum dolor sit amet consectetur"
            @click.native="closeFaqModal"
		/>
		<Card
			v-if="online"
	        link="https://www.missiontoseafarers.org/contact-a-chaplain"
	        class="card-block-blue card-block-full"
	        icon="ChatIcon"
	        category="Flying Angel"
	        title="Speak to the flying angel"
	        text="Our interactive digital assistant"
	    />
	</div><!-- card wrap -->
</div><!-- modal -->
</template>

<script>
import FaqIcon from '@/assets/faq.svg';
import GreyCloseIcon from '@/assets/close-grey.svg';
import Card from '@/components/Card.vue';
export default {
name: 'FaqOverlay',
	props: {
		subtitle: String,
		title: String,
		icon: String
	},
	data () {
		return {};
	},
	components: {
		FaqIcon,
		GreyCloseIcon,
		Card
	},
    computed: {
        isFaqModalOpen () {
            return this.$store.state.isFaqModalOpen;
        },
        online () {
            return window.navigator.onLine;
        }
    },
	methods: {
        closeFaqModal () {
        	this.$store.commit('FAQ_TOGGLE');
        }
    },
	mounted () {
		this.$refs.searchInput.focus();
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.section-title-wrap .section-title {
		color: $grey;
	}
	.modal-title-bar {
		.icon-wrap {
			svg {
				g, path {
					stroke-width: 2.5;
					stroke: $red_dark;
				}
				.word {
					stroke-width: 0;
					fill: $red_dark;
				}
			}
			span {
				color: $red_dark;
			}
		}
	}
	.card-wrap {
		.card-block-grey {
			margin-bottom: 15px;
		}
	}
</style>
