<template>
	<div class="resources">
		<Nav />
		<span class="overlay" :class="{active: isMenuOpen || isFaqModalOpen}"></span>
		<div class="header header-standard">
			<HeaderNavBar back />
		</div><!-- header -->
		<div class="header-image red"></div><!-- header image -->
		<div class="content-wrap content-wrap-alt content-wrap-nav">
			<SectionTitle
				icon="FaqIcon"
				title="FAQs"
				colour="red"
			/>
			<div class="section-gap section-ports">
				<div class="section-title-wrap">
					<h2 class="section-title">18 Questions</h2>
				</div>
				<div class="faq-wrap">
					<Faq
                        @click.native="$store.commit('FAQ_TOGGLE');"
						title="This is the title"
						text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce enim neque…"
					/>
					<Faq
                        @click.native="$store.commit('FAQ_TOGGLE');"
						title="This is the title"
						text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce enim neque…"
					/>
					<Faq
                        @click.native="$store.commit('FAQ_TOGGLE');"
						title="This is the title"
						text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce enim neque…"
					/>
					<Faq
                        @click.native="$store.commit('FAQ_TOGGLE');"
						title="This is the title"
						text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce enim neque…"
					/>
					<Faq
                        @click.native="$store.commit('FAQ_TOGGLE');"
						title="This is the title"
						text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce enim neque…"
					/>
					<Faq
                        @click.native="$store.commit('FAQ_TOGGLE');"
						title="This is the title"
						text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce enim neque…"
					/>
					<Faq
                        @click.native="$store.commit('FAQ_TOGGLE');"
						title="This is the title"
						text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce enim neque…"
					/>
					<Faq
                        @click.native="$store.commit('FAQ_TOGGLE');"
						title="This is the title"
						text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce enim neque…"
					/>
				</div>
			</div>
		</div>
		<ResourceSearchOverlay />
		<ResourceAdviceOverlay />
		<FaqOverlay />
		<Menu />
	</div>
</template>
<script>
	import HeaderNavBar from '@/components/HeaderNavBar.vue';
	import Nav from '@/components/Nav.vue';
	import Menu from '@/components/Menu.vue';
	import SectionTitle from '@/components/SectionTitle.vue';
	import Faq from '@/components/Faq.vue';
	import ResourceSearchOverlay from '@/components/ResourceSearchOverlay.vue';
	import ResourceAdviceOverlay from '@/components/ResourceAdviceOverlay.vue';
	import FaqOverlay from '@/components/FaqOverlay.vue';

	export default {
		name: 'ResourcesFaqs',
		components: {
			Nav,
			HeaderNavBar,
			Menu,
			SectionTitle,
			Faq,
			FaqOverlay,
			ResourceSearchOverlay,
			ResourceAdviceOverlay
		},
		data () {
	        return {

	        };
	    },
	    methods: {

        },
	    computed: {
			isMenuOpen () {
	            return this.$store.state.isMenuOpen;
	        },
	        isFaqModalOpen () {
	            return this.$store.state.isFaqModalOpen;
	        }
		},
		mounted () {

        }
	};
</script>
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.section-title {
		color: $grey;
	}
</style>
