<template>
	<a href="#" class="faq-block">
		<h3>{{ title }}</h3>
		<p>{{ text }}</p>
	</a><!-- card -->
</template>

<script>
export default {
	name: 'Faq',
	props: {
		title: String,
		text: String
	},
	components: {
	}
};
</script>

<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.faq-block {
		position: relative;
		padding: 17px;
		border-radius: 12px;
		border: 1px solid $snow;
		display: block;
		justify-content: space-between;
		box-shadow: 5px 15px 15px 5px $snow;
		text-align: left;
		margin-bottom: 15px;
		&:last-of-type {
			margin-bottom: 0;
		}
        h3 {
			font-size: 17px;
			letter-spacing: -0.5px;
			line-height: 20px;
			color: $dark;
			font-weight: $weight_600;
			margin-bottom: 5px;
		}
		p {
			font-size: 14px;
			line-height: 19px;
			color: $mid;
			font-weight: $weight_400;
		}
	}
</style>
